import React from 'react'
import Slider from "react-slick";
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image';



class Banner extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {

    const settings = {
      dots: false,
      infinite: true,
      speed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1000
    };

    return (
      <section className="banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-banner">
              <div className="mw600">
                <h1 className="main-heading"><span className="bluecolor">OpenSea Clone</span> Script</h1>
                <p className="sub-heading">Create an NFT Marketplace like OpenSea instantly with our premium OpenSea Clone.</p>
                <ul>  
                  <li className="pharagraph">It is an easily customizable and budget-friendly solution.
                  </li>
                  <li className="pharagraph">A Completely decentralized P2P NFT Marketplace script.
                  </li>
                  <li className="pharagraph">Build an exclusive NFT Marketplace like OpenSea with advanced Web3 features.
                  </li>
                </ul>
                <div className="cta-banner">
                <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-banner">
            <div className="screen">
                <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/binance-new/laptop-binance.png"
                  alt="OpenSea clone laptop front view"
                  width={876}
                />
                <div className="outder">
                  <Slider {...settings}>
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/opensea-clone-script-screen-1.png"
                      alt="OpenSea clone script slider1"
                      width={647}
                    />
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/opensea-clone-script-screen-2.png"
                      alt="OpenSea clone script slider2"
                      width={647}
                    />
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/opensea-clone-script-screen-3.png"
                      alt="OpenSea clone script slider3"
                      width={647}
                    />
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Banner