import React from "react"

class DevMethod extends React.Component {
  state = {
    modal: false,
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    })
  }

  render() {
    return (
      <div className="pt-100">
      <section className="devmethod gray-bg mb-0 hdonly">
        <div className="container"> 
          <h4 className="heading-h2 text-center">
            <span className="bluecolor">Revenue Generating streams</span>  of our
            OpenSea Clone Script
          </h4>
          <p className="sub-heading-bold">
          Our OpenSea clone software helps entrepreneurs generate lucrative revenue in a short period.
          </p>
          <div className="d-lg-flex">
            <div className="square">
              <h5 className="head3">
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/opensea-new/transaction-fees.png"
                  alt="Transaction fees"
                />
                <span>Transaction fees</span>
              </h5>
            </div>
            <div className="square">
              <h5 className="head3">
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/opensea-new/registration-fees.png"
                  alt="Registration fees"
                />
                <span>Registration fees</span>
              </h5>
            </div>
          </div>
          <div className="d-lg-flex">
          <div className="square">
              <h5 className="head3">
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/opensea-new/minting-fees.png"
                  alt="Minting fees"
                />
                <span>Minting fees</span>
              </h5>
            </div>
            <div className="square">
              <h5 className="head3">
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/opensea-new/listing-fees.png"
                  alt="Listing fees"
                />
                <span>Listing fees</span>
              </h5>
            </div>
            <div className="square">
              <h5 className="head3">
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/opensea-new/auction-bidding-fee.png"
                  alt="Auction bidding fee"
                />
                <span>Auction bidding fee</span>
              </h5>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default DevMethod
