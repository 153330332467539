import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'



class WhyChoose extends React.Component {


  render() {

    return (
      <div className='pt-100'>
      <section className="mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h4 className="heading-h2"><span className="heading-h3 bluecolor">Why Choose Coinsclone's</span> OpenSea Clone Script to Develop an NFT Marketplace?</h4>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 left-side" >
            <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/opensea-new/why-choose-coinsclone-for-opensea-clone-script.png"
                        alt="Why Choose our OpenSea Clone Script"
                        width={450} />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph">To create <a href='https://www.coinsclone.com/white-label-nft-marketplace/'><b>White Label NFT Marketplace</b></a>, it’s significant to approach an eminent OpenSea clone script provider like Coinsclone in the market with high experience. We offer a user-friendly, reliable, and highly secure NFT Marketplace like OpenSea at a budget-friendly cost which is completely suitable for entry-level businesses. Our blockchain team of experts used the most efficient and cutting-edge technologies to build the clone software and is trusted globally by a large number of clients.
              </p>
              <ul className='mb-0'>
                <li>Prime solution</li>
                <li>Customization</li>
                <li>Fewer Efforts</li>
                <li>End-to-end support</li>
                <li>High ROI</li>
                <li>Low investment</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default WhyChoose