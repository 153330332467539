import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'




const HowOur = () => {



  return (
    <div className='pt-100'>
    <section className="how-our mb-0">
      <div className="container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

          <h2 className="heading-h2"><span className="heading-h3"><span className='bluecolor'>Working Process</span> Of Our</span>
          OpenSea Clone Software</h2>
        </div>
        <StaticImage
            src="https://coinsclone.mo.cloudinary.net/images/opensea-new/workflow-opensea.png"
            alt="Working Process of our Opensea clone software Image"
            width={1000}
          />
      </div>
    </section>
    </div>
  )
}

export default HowOur