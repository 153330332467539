import React from 'react'


class WhiteLable extends React.Component {


  render() {

    return (
      <div className='cryptoex'>
      <section className="pt-100 opensea">
        <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <h5 className="heading-h2"><span className="bluecolor">Future Trends </span>in Developing an NFT Marketplace like OpenSea</h5>
                <p className="text-center">Here are the latest trends you can integrate with our Opensea clone! </p>
                <div className='d-flex flex-wrap justify-content-evenly ind-std'>
                    <p>Metaverse</p>
                    <p>Artificial Intelligence</p>
                    <p>Avatar NFTs</p>
                    <p>Digital Twin NFTs</p>
                    <p>Bitcoin Ordinal NFTs</p>
                </div>
              </div>
            </div>
        </div>
      </section>
      </div>
    )
  }
}

export default WhiteLable