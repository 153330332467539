import { StaticImage } from 'gatsby-plugin-image';
import React, { Component } from 'react'


class CoreFeatures extends Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  // Tab
  openTabSection = (evt, tabName) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_items");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].classList.remove("fadeIn");
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabName).style.display = "block";
    document.getElementById(tabName).className += " fadeIn animated";
    evt.currentTarget.className += "current";
  }

  render() {
    return (
      <section className="trading bgremove pt-100 mb-0">

        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">

            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Core Features</span> of </span>
            Our OpenSea Clone Script</h3>
          </div>
          <div className="row">

            <div className="courses-details-desc">
              <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role='presentation'>
                <li className="current" role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab1')}>
                  NFT Wallet Integration
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab2')} >
                  Cross-chain Compatibility
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab7')} >
                  Royalties
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab8')} >
                  Token Search and filter
                </li>
                <li role='presentation' onKeyDown={this.openTabSection}
                  onClick={(e) => this.openTabSection(e, 'tab9')} >
                  Virtual NFT options
                </li>
              </ul>

              <div className="tab-content">
                <div id="tab1" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">NFT Wallet Integration
                        </h4>
                        <p className="pharagraph">Our NFT Marketplace like OpenSea allows integration of any popular crypto wallets for NFT storing and accessing. 
                        </p>
                        
                      </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea/crypto-wallets.png"
                      alt="NFT Wallet Integration"
                      width={380}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab2" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Cross-chain Compatibility
                        </h4>
                        <p className="pharagraph">The script is compatible with multiple blockchain networks such as Ethereum, Binance Smart Chain, Polygon, etc. 
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/multi-chain-support.png"
                      alt="Cross-chain Compatibility"
                      width={329}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab7" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Royalties
                        </h4>
                        <p className="pharagraph">The royalties feature facilitates creators to earn a small percentage of trading profit whenever their NFTs get resold.</p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/trending-collections.png"
                      alt="Royalties"
                      width={350}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab8" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Token Search and filter
                        </h4>
                        <p className="pharagraph">We exhibit an ultimate token search option to help your users identify, explore, and filter their desired NFTs without much effort. 
                        </p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/filter.png"
                      alt="Token Search and filter"
                      width={350}
                    />
                    </div>
                  </div>
                </div>
                <div id="tab9" className="tab-pane tabs_items">
                  <div className="row table-content orderflex">
                    <div className="col-md-6 order2">
                      <div className="mw466">
                        <h4 className="heading-h4">Virtual NFT options
                        </h4>
                        <p className="pharagraph">We ensure that our NFT Website has the capabilities to tokenize virtual lands and real-world collectibles to Metaverse. </p>
                         </div>
                    </div>
                    <div className="col-md-6  text-center order1" >
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/virtual-nft-options.webp"
                      alt="Virtual NFT options"
                      width={300}
                    />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default CoreFeatures